$color-white: #ffffff;
$color-light-white: #fafcf4;
$color-black: #000000;
$color-lightgrey: #d2d2d2;
$color-lightestgrey: #d3d3d3;
$color-gainsboro: #e1e1e1;
$color-darkgrey: #b2b2b2;
$color-suvagrey: #8f8f8f;
$color-bahiagreen: #a2c235;
$color-white-smoke: #f1f1f1;
$color-white-smoke-light: #f4f4f4;
$color-dark-white-smoke: #eeeeee;
$color-nero: #222222;
$color-neutral-grey: #292929;
$color-acadia: #666666;
$color-silver: #c3c3c3;
$color-nobel: #999999;
$text-inverted-disabled: #D7D7D7;
$color-mantis: #88ce68;
$color-limerick: #91B02A;
$color-roman: #db6666;
$color-panache: #FAFCF4;
$color-dark-green: #4F6013;
$color-dark-olive: #999B93;
$normal-font: "Roboto", sans-serif;
$ecs-theme: #768F1C;
$color-grey-chateyu: #a1a2a3;
$color-dark-grey: #494949;
$color-light-grey: #4D4E49;
$text-weak: #292929;
$neutral-grey-100: #F0F0EF;
$neutral-grey-200: #E4E4E4;
$neutral-grey-400: #CACACA;
$neutral-grey-500: #A6A6A6;
$neutral-grey-700: #535353;
$neutral-grey-800: $text-weak;
$text-inverted-default: #FAFAFA;
$text-default: #222;
$light-grey: #d9d9d9;
$text-inverted-weak: #F2F2F2;
$gradient-diagonal-01: #4F6013;
$color-hover: #9DBF26;
$color-weak-gray: #cecdcd;
$color-gray-800: #7D7D7D;
$color-box-shadow1: rgba(68, 70, 58, 0.15);
$color-box-shadow2: rgba(68, 70, 58, 0.20);
$color-error: #F5C7CE;
$color-primary-black: #AAAAAA;
$color-primary-gray-black: #444;
$color-primary-crimson: #971B2F;