@font-face {
  font-family: DoubletwoStudiosXXIINeueNormCndSemiBold;
  src: url("./DoubletwoStudiosXXIINeueNormCndSemiBold.otf") format("truetype");
}

@font-face {
  font-family: XXIINeueNorm-CndMedium;
  src: url("./XXIINeueNorm-CndMedium.woff2") format("truetype");
}

@font-face {
  font-family: Roboto-Light;
  src: url("./Roboto-Light.woff2") format("truetype");
}
