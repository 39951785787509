@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/font-awesome/css/font-awesome.css";
@import "node_modules/ngx-spinner/animations/ball-scale-multiple.css";
@import 'ngx-toastr/toastr-bs5-alert';
@import "../src/app/theme.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "./mixins/breakpoints.scss";


.main-wrapper {
    overflow-x: hidden !important;
    // scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: $color-white-smoke;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: $ecs-theme;
}

body {
    font-family: "Roboto", sans-serif !important;
    cursor: default;
    font-size: 14px !important;
    overflow: hidden;
    background-color: $color-white !important;
    min-height: 100% !important;
}

a {
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
    //font-family: DoubletwoStudiosXXIINeueNormCndSemiBold !important;
    font-family: XXIINeueNorm-CndMedium !important;
}



.heading {
    color: $color-black;
    background-color: $ecs-theme;
    padding: 5px;
    width: fit-content;
    font-size: xx-large;
}

input,
button {
    font-size: 14px !important;
    border-radius: 4px;
}

input:focus {
    background: #F9F9F9 0% 0% no-repeat padding-box;
    border: 1px solid $ecs-theme;
    opacity: 1;
}

.flex-basis-1 {
    flex-basis: 100%;
}

.w-30 {
    width: 30% !important;
}

.m10 {
    margin: 10px;
}

.m0 {
    margin: 0px;
}

.m3 {
    margin: 36px 0px 0px;
}

.m148 {
    margin-top: 24%;
}

.fs12 {
    font-size: 12px !important;
}

.fs13 {
    font-size: 13px !important;
}

.fs14 {
    font-size: 14px !important;
}

.fs15 {
    font-size: 15px !important;
}

.fs16 {
    font-size: 16px !important;
}

.fs18 {
    font-size: 18px !important;
}

.fs40 {
    font-size: 40px !important;
}

.fs25 {
    font-size: 25px !important;
}

.fs24 {
    font-size: 24px !important;
}

.p20 {
    padding: 3% 0% 3% 6%;
}

.flex-basis-0 {
    width: 33% !important;
}

.green-color-font {
    color: $ecs-theme;
}

.btn-with-border {
    border-color: $color-nero !important;
}

.btn {
    color: $color-nero !important;
    background-color: $color-white !important;
    min-width: 100px !important;
    height: 40px !important;
    font-size: 18px !important;
    line-height: 18px !important;
}

.btn:disabled {
    border-color: $color-white-smoke !important;
    border: 1px solid !important;
    opacity: 0.45 !important;
    cursor: not-allowed;
}


.btn-primary {
    border-color: $ecs-theme !important;
    color: $color-white !important;
    background-color: $ecs-theme !important;


    &:hover {
        background-color: $color-hover !important;
        box-shadow: 0px 4px 6px -4px rgba(68, 70, 58, 0.15), 0px 10px 15px -3px rgba(68, 70, 58, 0.20) !important;
        transform: translateY(-1px) !important;
        color: white !important;
        border: none;
    }

    &:active {
        background-color: $color-dark-green !important;
    }
}

.btn-disable {
    border-color: $color-roman !important;
    color: $color-white !important;
    background-color: $color-roman !important;
}

.ngx-file-drop__content {
    background-color: $color-white-smoke;
    border-radius: 5px;
}

.ngx-file-drop__drop-zone {
    border: 0px dotted $color-lightgrey !important;
    border-radius: 5px !important;
}

.caption {
    color: $color-nero !important;
}

.acadia {
    color: $color-acadia;
}

.form-check-label {
    color: $neutral-grey-700;

}

.popoverClass {
    min-width: 200px;
}


input {
    text-overflow: ellipsis;
}

.min-vh-80 {
    min-height: 80vh;
}

.flex-1 {
    flex: 1;
}

input[type="radio"] {
    display: none;
}

.gap-4p {
    gap: 4px !important;
}

input[type="radio"]+*::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.5rem;
    border-radius: 50%;
    border: 1px solid;
}

input[type="radio"]+*::before {
    border-color: $color-darkgrey;
}

input[type="radio"]:checked+*::before {
    background: radial-gradient($ecs-theme 0%, $ecs-theme 40%, transparent 40%, transparent);
    border-color: $ecs-theme;
}

input[type="radio"]+* {
    display: inline-block;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.pointer-cursor {
    cursor: pointer !important;
}

.form-control,
.form-select {
    //     border-radius: unset !important;
    border: 1px solid $color-lightgrey !important;
    //     opacity: 1 !important;

}

.form-control {
    &.password {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}

.input-group-text {
    background-color: $color-white !important;
}


// .form-control:read-only {
//     background: $color-white-smoke 0% 0% no-repeat padding-box !important;
// }

.form-control:focus,
.form-select:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem $ecs-theme !important;
}

.tipBox {
    top: 30%;
    width: 30%;
    min-height: 280px !important;
    border: none !important;
}

.btn:focus {
    box-shadow: none !important;
}


.paragraph,
label {
    // font-family: Roboto sans-serif;
    color: $text-weak;
    font-style: normal;
    //font-weight: 600;

}

.paragraph {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.68rem;

}

.content-wrapper {
    background: $color-white;
}

.custom-checkbox {
    accent-color: $ecs-theme !important;
    border-color: $color-hover !important;
}

.custom-checkbox:checked {
    background-color: $color-hover !important;
    border-color: $color-hover !important;
}


.custom-checkbox:focus {
    border-color: transparent !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.base-paragraph {
    font-size: 18px !important;
    //  font-family: Roboto !important;
    font-weight: 400 !important;
    line-height: 27px;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    color: #535353 !important;
}


.custom-anchor {
    color: var(--Primary-600, var(--text-weak, #768F1C));
    font-family: "Roboto", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
}

video {
    transition: opacity 300ms ease-in-out;
    opacity: 1;
    /* Softer transition between forward and backward */
}

.grey-container {
    background-color: $text-inverted-weak;
    padding: 6.25rem 20rem;

}

.container-desktop {
    display: block !important;
    box-shadow: 0 8px 10px rgba(68, 70, 58, .1), 0 20px 25px rgba(68, 70, 58, .2);
    padding: 0 !important;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 8px !important;
    // overflow: hidden;
}




.prevNextBtn {
    width: 100px;
    height: 32px;
    min-width: 100px;
    padding: 0px 10px;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $text-inverted-weak !important;

    .nextBtnImgIcon {
        margin-bottom: 1%;
        margin-left: 15%;
    }
}

.prevNextBtn:active {
    background-color: $ecs-theme !important;
    color: $color-white !important;

    img {
        filter: brightness(0) invert(1)
    }
}

.prevNextBtn.active {
    color: $color-white !important;
    background-color: $color-hover !important;

    img {
        filter: brightness(0) invert(1)
    }
}

.prevNextBtn:hover {
    background-color: $color-hover !important;
    cursor: pointer !important;
    color: $color-white !important;
    box-shadow: 0px 4px 6px -4px $color-box-shadow1, 0px 10px 15px -3px $color-box-shadow2;

    img {
        filter: brightness(0) invert(1)
    }
}



// Tablet Action button responsive design
@media only screen and (max-width: $mobile_max_width) {
    .tabletPrevNextBtnContainer {
        display: none !important;
    }

    .base-paragraph {
        font-size: 16px !important;
        line-height: 24px !important;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .tabletPrevNextBtnContainer {
        display: block !important;
    }

}

@media only screen and (min-width: $tablet_max_width) {
    .tabletPrevNextBtnContainer {
        display: none !important;
    }
}

.form-check {
    display: flex !important;
    align-items: baseline !important;
    justify-content: center !important;
}

.video-content {
    overflow: hidden;
    object-fit: cover;
    width: 100%
}

.btn-outline-primary {
    border-color: $ecs-theme !important;
}